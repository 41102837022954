<template lang="pug">
b-container(fluid)
  b-row(class='mb-4')
    b-col
      h1 Projects

  b-row(class='mb-4')
    b-col
      label(for="text-search" class="mr-4") Search
      b-form-input(id="text-search" class="" v-model="filter.q" @keydown.enter="setSearch")
  
    b-col
      label(for="status-select") Status
      c-select(
        :value="filter.Status"
        @input="$set(filter, 'Status', $event.target.value); setSearch()"
        id="status-select"
        :options="statusOptions"
      )
  
    b-col
      label(for="type-select") Type
      c-select(
        :value="filter.Type"
        @input="$set(filter, 'Type', $event.target.value); setSearch()"
        id="type-select"
        :options="projectTypeOptions"
      )
  
    b-col
      label(for="owner-select") Project Manager
      c-select(
        :value="filter.UserAccountID"
        @input="$set(filter, 'UserAccountID', $event.target.value); setSearch()"
        id="owner-select"
        :options="taskUsers"
      )

  b-row(class='mb-4')
    b-col
      button.btn.btn-primary(@click='setSearch') Search
      button.btn.btn-secondary.ml-3(
        v-for='projectType in projectTypes'
        @click='createProject(projectType)'
        :disabled='creating'
      ) Create {{ projectType.description }}
      b-spinner(v-if='creating')

  b-row(class='mt-3')
    b-col(v-if='filterLoaded')
      rest-table(endpoint='task-attributes' :filter='activeFilter' :fields='fields')
        template(v-slot:cell(Description)='data')
          router-link(:to="{ name: 'task-detail-overview', params: { id: data.item.TaskAttributeID } }")
            | {{ data.item.Description }}

        template(v-slot:cell(actions)='data')
          router-link(:to="{ name: 'task-detail-overview', params: { id: data.item.TaskAttributeID } }")
            b-icon-pencil-square

        template(v-slot:cell(SubProvider.Description)='data') {{ data.item.SubProviderID == null ? '-' : data.item.SubProvider.Description }}
        template(v-slot:cell(CreationDateTime)='data') {{ data.item.CreationDateTime|dateFormat }}
        template(v-slot:cell(CompletionDateTime)='data') {{ data.item.CompletionDateTime|dateFormat }}
        

</template>

<script>
  import * as Sentry from "@sentry/vue"

  export default {
    components: {
      RestTable: () => import('@/components/RestTable'),
    },
    props: {

    },
    data () {
      return {
        creating: false,
        filter: {
          Type: '',
          AttributeTypeID: '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E',
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        },
        activeFilter: {
          Type: '',
          AttributeTypeID: '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E',
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        },
        filterLoaded: false,
        projectTypes: [
          { code: 'AlbumProject', description: 'Album Project'},
          { code: 'AlbumSimpleProject', description: 'Simple Album Project'},
          { code: 'LicensingProjectSync', description: 'Licensing Project Sync'},
          { code: 'LicensingProjectCompilation', description: 'Licensing Project Compilation'},
          { code: 'IncomingClaim', description: 'Incoming Claim'},
          { code: 'OutgoingClaim', description: 'Outgoing Claim'},
        ],
        statusOptions: [
          { value: 'All', label: 'All'},
          { value: 'Active', label: 'Active states'},
          { value: 'Inactive', label: 'Inactive states'},
          { value: 'Waiting', label: 'Waiting'},
          { value: 'Open', label: 'Open'},
          { value: 'In Progress', label: 'In Progress'},
          { value: 'On Hold', label: 'On Hold'},
          { value: 'Waiting for Manufacturing', label: 'Waiting for Manufacturing'},
          { value: 'Completed', label: 'Completed'},
          { value: 'Resolved', label: 'Resolved'},
        ],
        taskUsers: [],
        fields: [
          {
            key: 'TaskReference',
            label: 'Reference',
            sortable: true,
          },
          {
            key: 'CreationDateTime',
            label: 'Created',
            sortable: true,
          },
          {
            key: 'CompletionDateTime',
            label: 'Due',
            sortable: true,
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'SubProvider.Description',
            label: 'Sub Provider',
            sortable: true,
          },
          {
            key: 'User.FullName',
            label: 'Project Manager',
            sortable: true,
          },
          {
            key: 'Description',
            label: 'Description',
            sortable: true,
          },
          {
            key: 'actions',
            label: 'Actions',
            class: 'link-icons',
          },
        ],
      }
    },
    watch: {
      activeFilter: {
        deep: true,
        handler () {

        },
      },
    },
    methods: {
      setSearch () {
        const filter = JSON.parse(JSON.stringify(this.filter))
        delete filter.ParentTaskAttributeID
        delete filter.AttributeTypeID
        delete filter.Type
        delete filter.RootUserAccountID

        localStorage.setItem('taskFilter', JSON.stringify(filter))

        Object.assign(this.activeFilter, this.filter)
      },
      async createProject (template) {
        if (this.creating) return
        this.creating = true

        const data = {
          AttributeTypeID: '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E',
          SubProviderID: template.code.startsWith('LicensingProject') ? '2F66E19B-A682-4317-940D-04171C562F81' : '66D29407-AB49-495E-B990-DAE9C76BB5AF',
          ProviderID: 'A62DDEE1-5975-453F-9A9E-05FFE3472626',
          UserAccountID: this.$root.$data.me.UserAccountID,
          Description: `New ${template.description} created by ${this.$root.$data.me.FullName}`,
          Status: 'Open',
          Template: template.code,
        }

        try {
          const response = await this.$http.post('task-attributes', data)
          console.log(response)
          
          this.$router.push({ name: 'task-detail-overview', params: { id: response.data.TaskAttributeID } })
        } catch (error) {
          this.creating = false

          Sentry.captureException(error)

          this.$notify({
              type: 'danger',
              text: 'An error occurred while creating project, please reload the page and try again.'
            });
        }
      }
    },
    computed: {
      projectTypeOptions () {
        return [{value: '', label: 'All'}].concat(
          this.projectTypes.map(opt => { return { value: opt.code, label: opt.description } })
        )
      }
    },
    async mounted () {
      // Get users
      const response = await this.$http.get('task-users')
      const userList = response.data.map(user => { return { value: user.UserAccountID, label: user.FullName } })
      userList.unshift({
        label: 'All',
        value: ''
      })

      this.$set(
        this,
        'taskUsers', 
        userList
      )

      let filter = JSON.parse(localStorage.getItem('taskFilter'))
      if (!filter) {
        filter = {
          Status: 'Active',
          UserAccountID: this.$root.$data.me.UserAccountID,
          q: '',
        }
      }
      delete filter.RootUserAccountID

      filter.AttributeTypeID = '4B3569DB-A7E8-46A6-A88F-DB6CEB00FA5E'
      Object.assign(this.filter, filter)
      this.setSearch()
      this.filterLoaded = true 
    }
  }
</script>

<style scoped>

</style>
